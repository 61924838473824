
import './App.css';
import Course from './pages/Course';

function App() {
  return (
    <div className="App">
      <Course/>
    </div>
  );
}

export default App;
