import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Card, Button, Badge, Modal } from 'react-bootstrap';

const Course = () => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [joinTelegram, setJoinTelegram] = useState(false);

  const courses = [
    {
      id: 1,
      title: 'Zero To Millionaire',
      price: 34.9,
      rating: 4.9,
      description: '𝑻𝒓𝒂𝒏𝒔𝒇𝒐𝒓𝒎 𝒚𝒐𝒖𝒓 𝒍𝒊𝒇𝒆 𝒘𝒊𝒕𝒉 𝒕𝒉𝒆 𝒖𝒍𝒕𝒊𝒎𝒂𝒕𝒆 𝒃𝒍𝒖𝒆𝒑𝒓𝒊𝒏𝒕 𝒕𝒐 𝒇𝒊𝒏𝒂𝒏𝒄𝒊𝒂𝒍 𝒇𝒓𝒆𝒆𝒅𝒐𝒎: 𝑳𝒆𝒂𝒓𝒏 𝒕𝒉𝒆 𝒑𝒓𝒐𝒗𝒆𝒏 𝒔𝒕𝒓𝒂𝒕𝒆𝒈𝒊𝒆𝒔 𝒕𝒐 𝒈𝒐 𝒇𝒓𝒐𝒎 𝒛𝒆𝒓𝒐 𝒕𝒐 𝒎𝒊𝒍𝒍𝒊𝒐𝒏𝒂𝒊𝒓𝒆—𝒏𝒐 𝒆𝒙𝒑𝒆𝒓𝒊𝒆𝒏𝒄𝒆 𝒓𝒆𝒒𝒖𝒊𝒓𝒆𝒅!',
      paypalLink: 'https://www.paypal.com/ncp/payment/83DLJ33G3PUWY',
      razorpayLink: 'https://rzp.io/rzp/f5IWfWg',
    },
    {
      id: 2,
      title: 'Multi Millionaire',
      price: 49.9,
      rating: 5,
      description: '𝑼𝒏𝒍𝒐𝒄𝒌 𝒕𝒉𝒆 𝒔𝒆𝒄𝒓𝒆𝒕𝒔 𝒐𝒇 𝒉𝒐𝒘 𝒕𝒉𝒆 𝒕𝒐𝒑 𝟏% 𝒕𝒉𝒊𝒏𝒌, 𝒂𝒄𝒕, 𝒂𝒏𝒅 𝒃𝒖𝒊𝒍𝒅 𝒖𝒏𝒔𝒕𝒐𝒑𝒑𝒂𝒃𝒍𝒆 𝒘𝒆𝒂𝒍𝒕𝒉 – 𝒍𝒆𝒂𝒓𝒏 𝒑𝒓𝒐𝒗𝒆𝒏 𝒔𝒕𝒓𝒂𝒕𝒆𝒈𝒊𝒆𝒔 𝒕𝒉𝒂𝒕 𝒕𝒂𝒌𝒆 𝒚𝒐𝒖 𝒇𝒓𝒐𝒎 𝒘𝒉𝒆𝒓𝒆 𝒚𝒐𝒖 𝒂𝒓𝒆 𝒕𝒐 𝒎𝒊𝒍𝒍𝒊𝒐𝒏𝒂𝒊𝒓𝒆 𝒔𝒕𝒂𝒕𝒖𝒔 𝒇𝒂𝒔𝒕𝒆𝒓 𝒕𝒉𝒂𝒏 𝒚𝒐𝒖 𝒆𝒗𝒆𝒓 𝒕𝒉𝒐𝒖𝒈𝒉𝒕 𝒑𝒐𝒔𝒔𝒊𝒃𝒍𝒆.',
      paypalLink: 'https://www.paypal.com/ncp/payment/BDKW3ULMKRSA4',
      razorpayLink: 'https://rzp.io/rzp/BzS4qda',
    },
  ];

  const handlePurchase = (course) => {
    setSelectedCourse(course);
    setJoinTelegram(false); // Reset Join Telegram flag
    setShowPaymentModal(true);
  };

  const handleJoinTelegram = () => {
    setJoinTelegram(true);
    setSelectedCourse(null); // Reset course selection
    setShowPaymentModal(true);
  };

  const handlePayPalPayment = () => {
    let paypalUrl = '';

    if (joinTelegram) {
      paypalUrl = 'https://www.paypal.com/ncp/payment/6W4EGDX66VVRS'; // Link for Join Telegram
    } else if (selectedCourse) {
      paypalUrl = selectedCourse.paypalLink; // Link based on selected course
    }

    // Open PayPal payment in a new window/tab
    if (paypalUrl) {
      const win = window.open(paypalUrl, '_blank');

      // if (!win) {
      //   alert('Unable to open PayPal window. Redirecting to Telegram.');
      //   window.location.href = 'https://t.me/yourtelegramchannel'; // Redirect to Telegram if PayPal fails
      // }
    }

    setShowPaymentModal(false);
  };

  const makePaymentWithRazorpay = () => {
    if (joinTelegram) {
      window.location.href = 'https://rzp.io/rzp/DDeHl27'; // Razorpay link for Telegram join
    } else if (selectedCourse) {
      if (selectedCourse.razorpayLink) {
        window.location.href = selectedCourse.razorpayLink; // Redirect to Razorpay for the selected course
      }
    }
    setShowPaymentModal(false);
  };

  return (
    <div
      className="bg-dark d-flex flex-column"
      style={{
        position: 'relative',
        backgroundImage: 'url(/images/Background.webp)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        backgroundAttachment: 'fixed',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1,
        }}
      ></div>

      <Container className="py-5 text-light flex-grow-1 position-relative" style={{ zIndex: 2 }}>
        <h1 className="text-center mb-5 font-weight-bold text-white display-4">𝙲𝙷𝚁𝙸𝚂𝚃𝙼𝙰𝚂 𝙼𝙸𝚁𝙰𝙲𝙻𝙴</h1>
        <Row>
          {courses.map((course) => (
            <Col md={6} key={course.id} className="mb-4">
              <Card
                className="h-100 shadow-lg border-0 rounded-lg"
                style={{
                  position: 'relative',
                  background: 'rgba(0, 0, 0, 0.7)', // Ensure card background is transparent
                  border: '2px solid black',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow = '0 10px 30px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = 'none';
                }}
              >
               
                <Card.Body className='position-relative'>
                  <h3 className="text-center font-weight-bold text-white">{course.title}</h3>
                  <div className="d-flex justify-content-center align-items-center mb-4">
                  <p className="mr-2 text-white" style={{
      fontSize: '1.5rem',       // Larger font size
      fontWeight: 'bold',       // Make it bold
      color: '#FFD700'          // Gold color to highlight
    }}>
      ${course.price}
    </p>
                    <Badge pill variant="success"  style={{
      position: 'absolute',
      top: '10px',
      left: '10px',
      fontSize: '1rem',
      zIndex: 1,
    }}>{course.rating} ★</Badge>
                  </div>
                  <p className='text-white'>{course.description}</p>
                  <div className="d-flex justify-content-between">
                    <Button
                      variant="outline-light"
                      onClick={() => handlePurchase(course)}
                    >
                      Buy Now
                    </Button>
                    {/* <Button
                      variant="outline-light"
                      onClick={handleJoinTelegram}
                    >
                      Join Telegram
                    </Button> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Join Telegram Card */}
        <Col md={12} className="mb-4 d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
          <Card
            className="h-auto shadow-lg border-0 rounded-lg text-center"
            style={{
              maxWidth: '500px',
              margin: '0 auto',
              cursor: 'pointer',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
              boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)', // Optional shadow to give some depth
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
            onClick={handleJoinTelegram}
          >
            <Card.Body>
              <h4 className="text-light">Join our Telegram Channel</h4>
              <Button variant="outline-light" className="px-4 py-2 mt-4 rounded-pill">
                Join Now
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <footer
  className="text-light text-center py-4 mt-5"
  style={{
    zIndex: 2,
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0)', // Set transparent background
  }}
>
  <Container>
    <p className="mb-3">For any queries</p>
    <div className="d-flex justify-content-center align-items-center">
      <a
        href="https://t.me/+PcnDAMSF-OdiM2U9"
        target="_blank"
        rel="noopener noreferrer"
        className="mx-2"
      >
        <img
          src="/images/Telegram.jpg"
          alt="Telegram"
          style={{ width: '20px', height: '20px' }}
        />
      </a>
      <a
        href="mailto:cedkart01@gmail.com"
        className="mx-2"
      >
        <img
          src="/images/Gmail.jpg"
          alt="Gmail"
          style={{ width: '20px', height: '20px' }}
        />
      </a>
    </div>
  </Container>
</footer>

        {/* Payment Modal */}
        <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Complete Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              variant="primary"
              className="w-100 mb-3"
              onClick={handlePayPalPayment}
            >
              Pay with PayPal
            </Button>
            <Button
              variant="primary"
              className="w-100"
              onClick={makePaymentWithRazorpay}
            >
              Pay with Razorpay
            </Button>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default Course;
